import authservice from '../services/authservice'
import organizationservice from '../services/organizationservice'
import clientservice from '../services/clientservice'
import siteservice from '../services/siteservice'
import groupservice from '../services/groupservice'
import lessonservice from '../services/lessonservice'
import planningservice from '../services/planningservice'
import membershipservice from '../services/membershipservice'
import userservice from '../services/userservice'
import paymenttypeservice from '../services/paymenttypeservice'
import membershiptypeservice from '../services/membershiptypeservice'
import paymentservice from '../services/paymentservice'
import countryservice from '../services/countryservice'
import publicservice from '../services/publicservice'
import publicorganizationservice from '../services/publicorganizationservice'
import blogservice from '../services/blogservice'

const Helpers = {
    install(Vue, /*options*/) {
        Vue.prototype.$services = {
            get auth() {
                return authservice;
            },
            get organization() {
                return organizationservice;
            },
            get client() {
                return clientservice;
            },
            get site() {
                return siteservice;
            },
            get group() {
                return groupservice;
            },
            get lesson() {
                return lessonservice;
            },
            get planning() {
                return planningservice;
            },
            get membership() {
                return membershipservice;
            },
            get user() {
                return userservice;
            },
            get paymentType() {
                return paymenttypeservice;
            },
            get membershipType() {
                return membershiptypeservice;
            },
            get payment() {
                return paymentservice;
            },
            get country() {
                return countryservice;
            },
            get public() {
                return publicservice;
            },
            get publicorganization() {
                return publicorganizationservice;
            },
            get blog() {
                return blogservice;
            },
        }
    }
}

export default Helpers