import { http, httpAuthPublicOrganization } from '@/plugins/http'

class PublicOrganizationService {

  //public 

  get(url) {
    return http.get(`/publicorganization/${url}`);
  }
  register(url, client) {
    return http.post(`/publicorganization/${url}/register`, client);
  }
  login(url, client) {
    return http.post(`/publicorganization/${url}/login`, client);
  }

  getAllPlanning(url, auth, date) {
    return auth ? httpAuthPublicOrganization(url).get(`/publicorganization/${url}/planning?date=${date}`) : http.get(`/publicorganization/${url}/planning?date=${date}`);
  }

  resetPassword(url, login) {
    return http.post(`/publicorganization/${url}/resetpassword`, {
      login: login,
    });
  }

  resetPasswordFromToken(url, token, password) {
    return http.post(`/publicorganization/${url}/resetpassword`, {
      token: token,
      password: password,
    });
  }

  // authenticated

  getCurrentClient(url) {
    return httpAuthPublicOrganization(url).get(`/publicorganization/${url}/me`);
  }
  getClientMemberships(url) {
    return httpAuthPublicOrganization(url).get(`/publicorganization/${url}/me/memberships`);
  }
  getClientPlannings(url) {
    return httpAuthPublicOrganization(url).get(`/publicorganization/${url}/me/plannings`);
  }
  getMembershipTypes(url, planningId) {
    return httpAuthPublicOrganization(url).get(`/publicorganization/${url}/membershiptypes?planning_id=${planningId}`);
  }
  getPaymentTypes(url, planningId) {
    return httpAuthPublicOrganization(url).get(`/publicorganization/${url}/paymenttypes?planning_id=${planningId}`);
  }
  getPaymentConfirmation(url, paymentId) {
    return httpAuthPublicOrganization(url).get(`/publicorganization/${url}/payments/${paymentId}`);
  }
  payMembershipType(url, membershipTypeId, paymentTypeId) {
    return httpAuthPublicOrganization(url).post(`/publicorganization/${url}/membershiptypes/${membershipTypeId}/pay/${paymentTypeId}`);
  }
  bookPlanning(url, planningId, mode, {
    membershipId,
    membershipTypeId,
    paymentTypeId, }) {
    return httpAuthPublicOrganization(url).post(`/publicorganization/${url}/planning/${planningId}/book/${mode}`, {
      membershipId: membershipId,
      membershipTypeId: membershipTypeId,
      paymentTypeId: paymentTypeId,
    });
  }
  unbookPlanningMembership(url, planningMembershipId) {
    return httpAuthPublicOrganization(url).post(`/publicorganization/${url}/planningmembership/${planningMembershipId}/unbook`);
  }
  getPlanningMemberships(url, planningId) {
    return httpAuthPublicOrganization(url).get(`/publicorganization/${url}/planning/${planningId}/memberships`);
  }
}

export default new PublicOrganizationService();